.testimonial-section {
    height: 740px;
    position: relative;
    width: 1440px;
}

.testimonial-section .frame-2 {
    align-items: flex-start;
    display: inline-flex;
    gap: 535px;
    left: 112px;
    position: absolute;
    top: 122px;
}

.testimonial-section .frame-3 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 24px;
    position: relative;
}

.testimonial-section .text-wrapper-4 {
    color: #20b15a;
    font-family: "Poppins-Medium", Helvetica;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
}

.testimonial-section .see-what-our {
    color: #000000;
    font-family: "Poppins-SemiBold", Helvetica;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: 495px;
}

.testimonial-section .img {
    flex: 0 0 auto;
    position: relative;
}

.testimonial-section .frame-wrapper {
    height: 260px;
    left: 124px;
    overflow-x: scroll;
    position: absolute;
    top: 362px;
    width: 1316px;
}

.testimonial-section .frame-4 {
    align-items: flex-start;
    display: inline-flex;
    gap: 45px;
    padding: 0px 100px 0px 0px;
    position: relative;
}

.testimonial-section .frame-29 {
    flex: 0 0 auto !important;
}


