.footer {
    background-color: #00ca72;
    height: 441px;
    position: relative;
    width: 100%;
  }
  
  .footer .overlap-group {
    height: 178px;
    left: 106px;
    position: absolute;
    top: 57px;
    width: 338px;
  }
  
  .footer .frame {
    align-items: flex-start;
    display: inline-flex;
    gap: 19.6px;
    left: 0;
    padding: 19.6px;
    position: absolute;
    top: 0;
  }
  
  .footer .text-wrapper {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 39.2px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.96px;
    position: relative;
    width: fit-content;
  }
  
  .footer .some-footer-text {
    color: #000000;
    font-family: "Avenir-Roman", Helvetica;
    font-size: 20px;
    font-weight: 400;
    left: 10px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 97px;
    width: 328px;
  }
  
  .footer .frame-wrapper {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 27.94px;
    left: 106px;
    position: absolute;
    top: 256px;
  }
  
  .footer .div {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 15.31px;
    position: relative;
  }
  
  .footer .group {
    background-color: #20b15a;
    border-radius: 20.41px;
    height: 40.82px;
    position: relative;
    width: 40.82px;
  }
  
  .footer .vector {
    height: 18px;
    left: 16px;
    position: absolute;
    top: 11px;
    width: 9px;
  }
  
  .footer .img {
    height: 14px;
    left: 13px;
    position: absolute;
    top: 14px;
    width: 17px;
  }
  
  .footer .vector-2 {
    height: 15px;
    left: 13px;
    position: absolute;
    top: 11px;
    width: 15px;
  }
  
  .footer .frame-2 {
    flex: 0 0 auto;
    position: relative;
  }
  
  .footer .text-wrapper-2 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 20px;
    font-weight: 600;
    left: 681px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 94px;
  }
  
  .footer .text-wrapper-3 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 20px;
    font-weight: 600;
    left: 1066px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 92px;
  }
  
  .footer .frame-3 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 25px;
    left: 681px;
    position: absolute;
    top: 141px;
  }
  
  .footer .text-wrapper-4 {
    color: #000000;
    font-family: "Avenir-Medium", Helvetica;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
  }
  
  .footer .text-wrapper-5 {
    color: #000000;
    font-family: "Avenir-Medium", Helvetica;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: fit-content;
  }
  
  .footer .design-agency-head {
    color: #000000;
    font-family: "Avenir-Medium", Helvetica;
    font-size: 20px;
    font-weight: 500;
    left: 1066px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 148px;
  }
  
  .footer .text-wrapper-6 {
    color: #000000;
    font-family: "Avenir-Medium", Helvetica;
    font-size: 20px;
    font-weight: 500;
    left: 116px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 384px;
  }
  