.work {
    align-items: center;
    background-color: #d6f5db;
    border-radius: 20px;
    display: inline-flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    padding: 30px;
    position: relative;
  }
  
  .work .img-3 {
    height: 32.89px;
    position: relative;
    width: 33.32px;
  }
  