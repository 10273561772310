.main-menu-content {
    align-items: center;
    display: flex;
    height: 91.7px;
    justify-content: space-between;
    padding: 5.73px 114.62px;
    position: relative;
    width: 100%;
  }
  
  .main-menu-content .design-AGENCY-wrapper {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 11.46px;
    padding: 11.46px;
    position: relative;
  }
  
  .main-menu-content .design-AGENCY {
    color: transparent;
    font-family: "Poppins", Helvetica;
    font-size: 22.9px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.15px;
    position: relative;
    width: fit-content;
  }
  
  .main-menu-content .text-wrapper {
    color: #000000;
  }
  
  .main-menu-content .span {
    color: #f55f1d;
  }
  
  .main-menu-content .div {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 34.39px;
    justify-content: center;
    position: relative;
  }
  
  .main-menu-content .frame-1 {
    flex: 0 0 auto !important;
    gap: 11.46px !important;
    padding: 11.46px !important;
  }
  
  .main-menu-content .frame-instance {
    font-size: 18.3px !important;
    margin-top: -1.15px !important;
  }
  
  .main-menu-content .frame-1-instance {
    border: 1.15px solid !important;
    border-color: #20b15a !important;
    border-radius: 11.46px !important;
    display: flex !important;
    gap: 11.46px !important;
    padding: 11.46px !important;
    width: 106.6px !important;
  }
  
  .main-menu-content .design-component-instance-node {
    background-color: #20b15a !important;
    border-radius: 11.46px !important;
    display: flex !important;
    gap: 11.46px !important;
    padding: 11.46px !important;
    width: 120.36px !important;
  }
  
  .main-menu-content .frame-2 {
    color: #ffffff !important;
    font-size: 18.3px !important;
    margin-top: -1.15px !important;
  }
  