/* attribute-card.css */
.attribute-card-container {
    display: flex;
    justify-content: center;
    background-color: #f9fffa;
  }
  
.attribute-card {
    padding: 20px;
    margin: 10px;
    text-align: center;
  }
.attribute-card:hover{
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.attribute-icon {
    margin-bottom: 10px;
}
.attribute-title {
    height: 24px;
    width: 160px;
}
.attribute-title p {
    color: #000000;
    font-family: "Poppins-Medium", Helvetica;
    font-size: 16px;
    font-weight: 500;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: center;
}

.attribute-value p {
    color: #20b15a;
    font-family: "Avenir-Black", Helvetica;
    font-size: 40px;
    font-weight: 900;
    left: 0;
    letter-spacing: 0;
    position: relative;
    top: 0;
}