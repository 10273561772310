.our-portfolio {
    height: 1626px;
    position: relative;
    width: 100%;
}

.our-portfolio .text-wrapper {
    color: #20b15a;
    font-family: "Poppins-Medium", Helvetica;
    font-size: 20px;
    font-weight: 500;
    left: 113px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 61px;
}

.our-portfolio .div {
    color: #000000;
    font-family: "Poppins-SemiBold", Helvetica;
    font-size: 30px;
    font-weight: 600;
    left: 113px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 115px;
    width: 495px;
}

.our-portfolio .frame {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 42.79px;
    left: 113px;
    position: absolute;
    top: 279px;
}

.our-portfolio .screenshot {
    height: 342.31px;
    object-fit: cover;
    position: relative;
    width: 561px;
}

.our-portfolio .frame-2 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 14.26px;
    position: relative;
}

.our-portfolio .text-wrapper-2 {
    color: #000000;
    font-family: "Poppins-SemiBold", Helvetica;
    font-size: 22.8px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.95px;
    position: relative;
    width: fit-content;
}

.our-portfolio .p {
    color: #000000;
    font-family: "Avenir-Book", Helvetica;
    font-size: 15.2px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: 533.43px;
}

.our-portfolio .frame-3 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 42.64px;
    left: 769px;
    position: absolute;
    top: 279px;
}

.our-portfolio .img {
    height: 521.21px;
    object-fit: cover;
    position: relative;
    width: 561.15px;
}

.our-portfolio .frame-4 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 14.21px;
    position: relative;
}

.our-portfolio .text-wrapper-3 {
    color: #000000;
    font-family: "Poppins-SemiBold", Helvetica;
    font-size: 22.7px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.95px;
    position: relative;
    width: fit-content;
}

.our-portfolio .text-wrapper-4 {
    color: #000000;
    font-family: "Avenir-Book", Helvetica;
    font-size: 15.2px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: 531.63px;
}

.our-portfolio .frame-5 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 42.79px;
    left: 113px;
    position: absolute;
    top: 874px;
}

.our-portfolio .screenshot-2 {
    height: 522.97px;
    position: relative;
    width: 561px;
}

.our-portfolio .frame-6 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 55.4px;
    left: 769px;
    position: absolute;
    top: 1050px;
}

.our-portfolio .screenshot-3 {
    height: 338.03px;
    object-fit: cover;
    position: relative;
    width: 554px;
}

.our-portfolio .frame-7 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 14.08px;
    position: relative;
}

.our-portfolio .text-wrapper-5 {
    color: #000000;
    font-family: "Poppins-SemiBold", Helvetica;
    font-size: 22.5px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.94px;
    position: relative;
    width: fit-content;
}

.our-portfolio .text-wrapper-6 {
    color: #000000;
    font-family: "Avenir-Book", Helvetica;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: 526.77px;
}
