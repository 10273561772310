.activity {
    align-items: center;
    background-color: #d6f5db;
    border-radius: 20px;
    display: inline-flex;
    gap: 10px;
    justify-content: center;
    padding: 30px;
    position: relative;
  }
  
  .activity .img {
    height: 33.33px;
    position: relative;
    width: 33.33px;
  }
  