.what-we-do {
    height: 800px;
    width: 100%;
  }
  
  .what-we-do .div {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 40px;
    left: 105px;
    position: relative;
    top: 67px;
  }
  
  .what-we-do .frame-2 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 15px;
    position: relative;
  }
  
  .what-we-do .text-wrapper-2 {
    color: #20b15a;
    font-family: "Poppins", Helvetica;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
  }
  
  .what-we-do .p {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: 495px;
  }
  
  .what-we-do .frame-3 {
    align-items: center;
    display: flex;
    gap: 220px;
    position: relative;
  }
  
  .what-we-do .what-we-do-card-instance {
    height: 14px !important;
    margin-right: unset !important;
    width: 16px !important;
  }
  
  .what-we-do .design-component-instance-node {
    flex: 0 0 auto !important;
  }
  