.frame {
    align-items: center;
    display: inline-flex;
    gap: 10px;
    justify-content: center;
    padding: 10px;
    position: relative;
  }
  
  .frame .menu-items a {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
  }
  