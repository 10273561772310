@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400&display=swap');

/* custom colors*/

* {
  margin : 0;
  padding : 0;
  box-sizing: border-box;
  font-family: "Nunito",san-sarif;
}

:root {
  --bg-color:#f1f5fd;
  --primary-color:#20b15a;;
  --text-color:#828286;
  --main-text-color:#333333;
}

html {
  font:50.5%;
}

a {
  text-decoration: none;
  color:#fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
 color:#333333;
}

/*------------ navbar styling starts --------------*/
.navbar-bg{
  background-color: var(--bg-color);
  z-index: 99;
  position: relative;
}
.navbar-brand{
  font-size: 2rem;
}
.navbar li {
  padding: 0 1rem;
}
.navbar li a{
 font-size: 1rem;
 font-weight: lighter;
}
.navbar li a:hover{
  font-size: 1rem;
  color:var(--primary-color)!important;
  text-shadow: 0 0.2rem 0.2rem rgba(183, 171, 255, 0.5);
 }

 .btn-style{
  width:6rem;
  height:2rem;
  color:#fff;
  background-color: var(--primary-color);
  border-radius: 2rem;
  text-transform: capitalize;
 }
 .btn-style:first-child{
  margin-right: 1rem;
 }
 .btn-style:hover{
  border:#20b15a;
  color:var(--primary-color);
  background-color: transparent;
 }
 .btn-style-border{
  border:#20b15a;
  color:var(--primary-color);
  background-color: transparent;
 }
 .btn-style-border:hover{
  color:#fff;
  background-color: var(--primary-color);
  border-radius: 2rem;
 }
/*------------ navbar styling ends --------------*/
/*--------- header styling starts------------------*/
header{
  background-color: var(--bg-color);
  min-height: 100vh;
  min-width: 100%;
}
.main-hero-container{
height:80vh;
display:flex;
}
.main-hero-container h1 {
  text-transform : capitalize;
  font-weight : bolder;
}
.main-hero-para {
  font-size: 1rem;
  color:var(--text-color);
  margin: 3rem 0 5rem 0;
  font-weight: lighter;
  width:75%;
}
.main-hero-container h3 {
  font-weight : lighter;
}
/*right side of hero section*/
.main-herosection-images{
  position: relative;
}
.main-herosection-images img{
width:60%;
height:75%;
margin-top: 10rem;
box-shadow:0 0.8rem 0.8rem rgba(0,0,0,0.25);
border-radius: 1.5rem;
position: absolute;
}
.main-herosection-images .main-hero-img2{
  position: absolute;
  width: 50%;
  height: 30%;
  left: 55%;
  top: -2%;
  box-shadow: 0.8rem 0 0.8rem rgba(0,0,0,0.25);
}
.input-group{
  position:relative !important;
}
.form-control-text{
  position:absolute;
  height:5rem;
  border:none;
  outline-style: none;
  padding-left:1.5rem !important;
  font-size:1.5rem;
  font-weight:lighter;
}
.input-group-button{
  position:absolute;
  right:30%;
  color:#fff;
  background-color: var(--primary-color);
  height:4rem;
  top:0.5rem;
  width:20%;
  border-radius: 2rem !important;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
}
/*--------- header styling ends------------------*/
/*-----work container section starts */
.work-container{
width:80vw;
padding:5rem;
box-shadow:0 0.5rem 1.2rem rgba(0,0,0,0.1);
margin-top: 1rem;
background-color: #fff;
border-radius: 0.4rem;
}
.work-container .main-heading{
font-size: 3rem;
font-weight: bolder;
margin-bottom: 4rem;
word-spacing: 1rem;
}
.work-container .work-container-subdiv{
padding: 0 2rem;
}
.work-container .sub-heading{
  font-size: 2rem;
  font-weight: normal;
}
.fontawesome-style{
  font-size:2rem;
  color: var(--primary-color);
  background-color: var(--bg-color);
  border-radius: 50%;
  height: 6rem;
  width: 6rem;
  display: grid;
  align-items: center;
  margin:2rem auto;
}

/*-----work container section ends */
/*-----About Us section starts */
.our-services img{
  width:75%;
  height:75vh;
  margin-top: 10rem;
  box-shadow: rgba(0,0,0,0.25)  0px 0.8rem 0.8rem;
  border-radius: 1.5rem;
}
.our-services .mini-title{
  text-transform: uppercase;
  font-weight: normal;
  margin: 3rem 0;
  text-align: left;
  font-size: 1.5rem;
}
.our-services .main-heading{
  font-weight: bolder ;
}
.our-services .our-services-info{
  margin-top: 3rem;
}
.our-services-number {
  border:1px solid var(--primary-color);
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  display: grid;
  place-items: center;
  font-size: 1rem;
}
.our-services .our-services-data{
  padding-left: 1rem;
  margin-right: 1rem;
}
.our-services .our-services-data p{
  margin:1rem 0;

}
/*-----About Us section ends */
/*-----Service  section starts */
.service-main-container{
  margin-top:5rem;
  padding:5rem 0;
}

.service-main-container .main-heading{
margin:3rem 0;
}
.work-container-subdiv{
  box-shadow:0 0.1rem 0.4rem rgba(0,0,0,0.16);
}
.work-container-subdiv .main-hero-para{
  width:100%;
}
.work-container-subdiv:hover{
  background-color: var(--bg-color);
  cursor: pointer;
}
.work-container-subdiv:hover > .fontawesome-style{
  background-color: #fff;

}
.service-main-container .work-container-subdiv .fontawesome-style{
  text-align: center;
  margin: 2rem 0;
}
@media(min-width:1400px){
  .service-container .col-xxl-4
  {
    flex:0 0 auto;
    width :30% !important;
  }
}
@media(min-width:992px){
  .service-container .col-lg-4{
    flex:0 0 auto;
    width: 30% !important;
    margin:2.5rem auto;
  }
}
/*-----Service section ends */

/*-----Contact us section starts */
.contactus-section{
margin:5rem 0;
}
.contact-leftside img{
  min-width: 80%;
  max-height:30rem;
  height: auto;
}

.contact-input-field{
  margin-bottom: 1.5rem;
}
.contact-rightside form .form-control{
  border:1px solid #ced4da;
  height:3rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  padding-left: 1rem;
  font-weight: lighter;
}
.contact-rightside form .form-check-input{
width:1.5rem;
height:1.5rem;
}
.form-checkbox-style{
  margin:3rem 1rem 0px;
}
.contact-rightside form .main-hero-para{
 font-size: 1rem;
 color: var(--text-color);
 margin: 0;
 font-weight: lighter;
 width:75%;
 padding:3rem 0px;
}
/*-----Contact us section ends */
/*----footer section starts */
footer{
  padding:2rem 0;
  background-color: #182334;
  color:#fff;
}
footer h2 {
  color: #fff;
}
footer li {
  list-style: none;
  /* font-size: 1.5rem; */
  font-weight: lighter;
  color: #fff;
  margin: 1rem 0;
  cursor: pointer;
}
footer li:hover{
  color:var(--primary-color);
}
footer .main-hero-para{
  margin: 0;
}
footer .fontawesome-style{
  font-size: 1.3rem;
  color:var(--primary-color);
  background-color: transparent;
  width: 0rem;
  height: 0rem;
  margin: 2rem 0;
  cursor: pointer;
}
footer .fontawesome-style:hover{
  transition:all 1s;
  animation:rotatefont 1s linear infinite alternate-reverse;
  color:#fff;
}
@keyframes rotatefont{
  0%{
    transform:scale(0.6);
  }
  100%{
    transform: scale(1.2);
  }
}
/*----footer section ends */
/* -----------responsive media queries start-------------*/
@media(min-width:1500px){
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl{
    max-width:1500px;
  }
}
@media(min-width:1400px){
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl{
    max-width:1400px;
  }
}
/* responsive media queries ends*/
