@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@300;400&display=swap);
/* custom colors*/

* {
  margin : 0;
  padding : 0;
  box-sizing: border-box;
  font-family: "Nunito",san-sarif;
}

:root {
  --bg-color:#f1f5fd;
  --primary-color:#20b15a;;
  --text-color:#828286;
  --main-text-color:#333333;
}

html {
  font:50.5%;
}

a {
  text-decoration: none;
  color:#fff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
 color:#333333;
}

/*------------ navbar styling starts --------------*/
.navbar-bg{
  background-color: #f1f5fd;
  background-color: var(--bg-color);
  z-index: 99;
  position: relative;
}
.navbar-brand{
  font-size: 2rem;
}
.navbar li {
  padding: 0 1rem;
}
.navbar li a{
 font-size: 1rem;
 font-weight: lighter;
}
.navbar li a:hover{
  font-size: 1rem;
  color:#20b15a!important;
  color:var(--primary-color)!important;
  text-shadow: 0 0.2rem 0.2rem rgba(183, 171, 255, 0.5);
 }

 .btn-style{
  width:6rem;
  height:2rem;
  color:#fff;
  background-color: #20b15a;
  background-color: var(--primary-color);
  border-radius: 2rem;
  text-transform: capitalize;
 }
 .btn-style:first-child{
  margin-right: 1rem;
 }
 .btn-style:hover{
  border:#20b15a;
  color:#20b15a;
  color:var(--primary-color);
  background-color: transparent;
 }
 .btn-style-border{
  border:#20b15a;
  color:#20b15a;
  color:var(--primary-color);
  background-color: transparent;
 }
 .btn-style-border:hover{
  color:#fff;
  background-color: #20b15a;
  background-color: var(--primary-color);
  border-radius: 2rem;
 }
/*------------ navbar styling ends --------------*/
/*--------- header styling starts------------------*/
header{
  background-color: #f1f5fd;
  background-color: var(--bg-color);
  min-height: 100vh;
  min-width: 100%;
}
.main-hero-container{
height:80vh;
display:flex;
}
.main-hero-container h1 {
  text-transform : capitalize;
  font-weight : bolder;
}
.main-hero-para {
  font-size: 1rem;
  color:#828286;
  color:var(--text-color);
  margin: 3rem 0 5rem 0;
  font-weight: lighter;
  width:75%;
}
.main-hero-container h3 {
  font-weight : lighter;
}
/*right side of hero section*/
.main-herosection-images{
  position: relative;
}
.main-herosection-images img{
width:60%;
height:75%;
margin-top: 10rem;
box-shadow:0 0.8rem 0.8rem rgba(0,0,0,0.25);
border-radius: 1.5rem;
position: absolute;
}
.main-herosection-images .main-hero-img2{
  position: absolute;
  width: 50%;
  height: 30%;
  left: 55%;
  top: -2%;
  box-shadow: 0.8rem 0 0.8rem rgba(0,0,0,0.25);
}
.input-group{
  position:relative !important;
}
.form-control-text{
  position:absolute;
  height:5rem;
  border:none;
  outline-style: none;
  padding-left:1.5rem !important;
  font-size:1.5rem;
  font-weight:lighter;
}
.input-group-button{
  position:absolute;
  right:30%;
  color:#fff;
  background-color: #20b15a;
  background-color: var(--primary-color);
  height:4rem;
  top:0.5rem;
  width:20%;
  border-radius: 2rem !important;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
}
/*--------- header styling ends------------------*/
/*-----work container section starts */
.work-container{
width:80vw;
padding:5rem;
box-shadow:0 0.5rem 1.2rem rgba(0,0,0,0.1);
margin-top: 1rem;
background-color: #fff;
border-radius: 0.4rem;
}
.work-container .main-heading{
font-size: 3rem;
font-weight: bolder;
margin-bottom: 4rem;
word-spacing: 1rem;
}
.work-container .work-container-subdiv{
padding: 0 2rem;
}
.work-container .sub-heading{
  font-size: 2rem;
  font-weight: normal;
}
.fontawesome-style{
  font-size:2rem;
  color: #20b15a;
  color: var(--primary-color);
  background-color: #f1f5fd;
  background-color: var(--bg-color);
  border-radius: 50%;
  height: 6rem;
  width: 6rem;
  display: grid;
  align-items: center;
  margin:2rem auto;
}

/*-----work container section ends */
/*-----About Us section starts */
.our-services img{
  width:75%;
  height:75vh;
  margin-top: 10rem;
  box-shadow: rgba(0,0,0,0.25)  0px 0.8rem 0.8rem;
  border-radius: 1.5rem;
}
.our-services .mini-title{
  text-transform: uppercase;
  font-weight: normal;
  margin: 3rem 0;
  text-align: left;
  font-size: 1.5rem;
}
.our-services .main-heading{
  font-weight: bolder ;
}
.our-services .our-services-info{
  margin-top: 3rem;
}
.our-services-number {
  border:1px solid #20b15a;
  border:1px solid var(--primary-color);
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  display: grid;
  place-items: center;
  font-size: 1rem;
}
.our-services .our-services-data{
  padding-left: 1rem;
  margin-right: 1rem;
}
.our-services .our-services-data p{
  margin:1rem 0;

}
/*-----About Us section ends */
/*-----Service  section starts */
.service-main-container{
  margin-top:5rem;
  padding:5rem 0;
}

.service-main-container .main-heading{
margin:3rem 0;
}
.work-container-subdiv{
  box-shadow:0 0.1rem 0.4rem rgba(0,0,0,0.16);
}
.work-container-subdiv .main-hero-para{
  width:100%;
}
.work-container-subdiv:hover{
  background-color: #f1f5fd;
  background-color: var(--bg-color);
  cursor: pointer;
}
.work-container-subdiv:hover > .fontawesome-style{
  background-color: #fff;

}
.service-main-container .work-container-subdiv .fontawesome-style{
  text-align: center;
  margin: 2rem 0;
}
@media(min-width:1400px){
  .service-container .col-xxl-4
  {
    flex:0 0 auto;
    width :30% !important;
  }
}
@media(min-width:992px){
  .service-container .col-lg-4{
    flex:0 0 auto;
    width: 30% !important;
    margin:2.5rem auto;
  }
}
/*-----Service section ends */

/*-----Contact us section starts */
.contactus-section{
margin:5rem 0;
}
.contact-leftside img{
  min-width: 80%;
  max-height:30rem;
  height: auto;
}

.contact-input-field{
  margin-bottom: 1.5rem;
}
.contact-rightside form .form-control{
  border:1px solid #ced4da;
  height:3rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  padding-left: 1rem;
  font-weight: lighter;
}
.contact-rightside form .form-check-input{
width:1.5rem;
height:1.5rem;
}
.form-checkbox-style{
  margin:3rem 1rem 0px;
}
.contact-rightside form .main-hero-para{
 font-size: 1rem;
 color: #828286;
 color: var(--text-color);
 margin: 0;
 font-weight: lighter;
 width:75%;
 padding:3rem 0px;
}
/*-----Contact us section ends */
/*----footer section starts */
footer{
  padding:2rem 0;
  background-color: #182334;
  color:#fff;
}
footer h2 {
  color: #fff;
}
footer li {
  list-style: none;
  /* font-size: 1.5rem; */
  font-weight: lighter;
  color: #fff;
  margin: 1rem 0;
  cursor: pointer;
}
footer li:hover{
  color:#20b15a;
  color:var(--primary-color);
}
footer .main-hero-para{
  margin: 0;
}
footer .fontawesome-style{
  font-size: 1.3rem;
  color:#20b15a;
  color:var(--primary-color);
  background-color: transparent;
  width: 0rem;
  height: 0rem;
  margin: 2rem 0;
  cursor: pointer;
}
footer .fontawesome-style:hover{
  transition:all 1s;
  animation:rotatefont 1s linear infinite alternate-reverse;
  color:#fff;
}
@-webkit-keyframes rotatefont{
  0%{
    -webkit-transform:scale(0.6);
            transform:scale(0.6);
  }
  100%{
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
}
@keyframes rotatefont{
  0%{
    -webkit-transform:scale(0.6);
            transform:scale(0.6);
  }
  100%{
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
}
/*----footer section ends */
/* -----------responsive media queries start-------------*/
@media(min-width:1500px){
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl{
    max-width:1500px;
  }
}
@media(min-width:1400px){
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl{
    max-width:1400px;
  }
}
/* responsive media queries ends*/

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.frame {
    align-items: center;
    display: inline-flex;
    grid-gap: 10px;
    gap: 10px;
    justify-content: center;
    padding: 10px;
    position: relative;
  }
  
  .frame .menu-items a {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  
.main-menu-content {
    align-items: center;
    display: flex;
    height: 91.7px;
    justify-content: space-between;
    padding: 5.73px 114.62px;
    position: relative;
    width: 100%;
  }
  
  .main-menu-content .design-AGENCY-wrapper {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    grid-gap: 11.46px;
    gap: 11.46px;
    padding: 11.46px;
    position: relative;
  }
  
  .main-menu-content .design-AGENCY {
    color: transparent;
    font-family: "Poppins", Helvetica;
    font-size: 22.9px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.15px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  
  .main-menu-content .text-wrapper {
    color: #000000;
  }
  
  .main-menu-content .span {
    color: #f55f1d;
  }
  
  .main-menu-content .div {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    grid-gap: 34.39px;
    gap: 34.39px;
    justify-content: center;
    position: relative;
  }
  
  .main-menu-content .frame-1 {
    flex: 0 0 auto !important;
    grid-gap: 11.46px !important;
    gap: 11.46px !important;
    padding: 11.46px !important;
  }
  
  .main-menu-content .frame-instance {
    font-size: 18.3px !important;
    margin-top: -1.15px !important;
  }
  
  .main-menu-content .frame-1-instance {
    border: 1.15px solid !important;
    border-color: #20b15a !important;
    border-radius: 11.46px !important;
    display: flex !important;
    grid-gap: 11.46px !important;
    gap: 11.46px !important;
    padding: 11.46px !important;
    width: 106.6px !important;
  }
  
  .main-menu-content .design-component-instance-node {
    background-color: #20b15a !important;
    border-radius: 11.46px !important;
    display: flex !important;
    grid-gap: 11.46px !important;
    gap: 11.46px !important;
    padding: 11.46px !important;
    width: 120.36px !important;
  }
  
  .main-menu-content .frame-2 {
    color: #ffffff !important;
    font-size: 18.3px !important;
    margin-top: -1.15px !important;
  }
  
.header-section {
    background-color: #d6f5db;
    height: 959px;
    position: relative;
    width: 100%;
  }
  
  .header-section .frame {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    grid-gap: 42.41px;
    gap: 42.41px;
    left: 115px;
    position: absolute;
    top: 272px;
  }
  
  .header-section .header-text-content {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    grid-gap: 11.46px;
    gap: 11.46px;
    position: relative;
  }
  
  .header-section .text-wrapper {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 57.3px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 74.5px;
    margin-top: -1.15px;
    position: relative;
    width: 630.44px;
  }
  
  .header-section .we-help-businesses {
    color: #000000;
    font-family: "Avenir-Medium", Helvetica;
    font-size: 22.9px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: 541.03px;
  }
  
  .header-section .header-CTA {
    all: unset;
    align-items: center;
    background-color: #20b15a;
    border-radius: 18.34px;
    box-sizing: border-box;
    display: inline-flex;
    flex: 0 0 auto;
    grid-gap: 11.46px;
    gap: 11.46px;
    justify-content: center;
    padding: 22.93px 34.39px;
    position: relative;
  }
  
  .header-section .div {
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 22.9px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.15px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  
  .header-section .our-partners {
    background-color: #f7fff8;
    height: 138px;
    left: 0;
    position: absolute;
    top: 783px;
    width: 100%;
  }
  
  .header-section .group {
    height: 36px;
    left: 132px;
    position: relative;
    top: 50px;
    width: 1355px;
  }
  
  .header-section .logos-monday {
    height: 33px;
    left: 608px;
    position: absolute;
    top: 0;
    width: 180px;
  }
  
  .header-section .logos-google {
    height: 33px;
    left: 0;
    position: absolute;
    top: 3px;
    width: 102px;
  }
  
  .header-section .trello-logo-blue {
    height: 33px;
    left: 274px;
    object-fit: cover;
    position: absolute;
    top: 3px;
    width: 163px;
  }
  
  .header-section .notion {
    height: 33px;
    left: 960px;
    object-fit: cover;
    position: absolute;
    top: 3px;
    width: 92px;
  }
  
  .header-section .slack {
    height: 33px;
    left: 1224px;
    object-fit: cover;
    position: absolute;
    top: 3px;
    width: 131px;
  }  
.heart {
    align-items: center;
    background-color: #d6f5db;
    border-radius: 20px;
    display: inline-flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    justify-content: center;
    padding: 30px;
    position: relative;
  }
  
  .heart .img-2 {
    height: 31.67px;
    position: relative;
    width: 33.33px;
  }
  
.activity {
    align-items: center;
    background-color: #d6f5db;
    border-radius: 20px;
    display: inline-flex;
    grid-gap: 10px;
    gap: 10px;
    justify-content: center;
    padding: 30px;
    position: relative;
  }
  
  .activity .img {
    height: 33.33px;
    position: relative;
    width: 33.33px;
  }
  
.what-we-do-card {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  grid-gap: 43px;
  gap: 43px;
  height: 415px;
  padding: 50px 30px;
  position: relative;
  width: 380px;
}

.what-we-do-card .frame {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  grid-gap: 15px;
  gap: 15px;
  position: relative;
}

.what-we-do-card .grow-your-business {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.what-we-do-card .we-help-identify-the {
  color: #000000;
  font-family: "Avenir-Roman", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 299px;
}

.what-we-do-card .learn-more-btn {
  all: unset;
  align-items: center;
  border-radius: 10px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  grid-gap: 10px;
  gap: 10px;
  padding: 15px 20px;
  position: relative;
}

.what-we-do-card .text-wrapper {
  font-family: "Avenir-Roman", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.what-we-do-card .vector {
  height: 16px;
  margin-right: -1px;
  position: relative;
  width: 18px;
}

.what-we-do-card .activity-instance {
  flex: 0 0 auto !important;
}

.what-we-do-card.hover {
  box-shadow: 0px 4px 20px #0000001a;
}

.what-we-do-card.hover .learn-more-btn {
  background-color: #f55f1d;
}

.what-we-do-card.hover .text-wrapper {
  color: #ffffff;
}

.what-we-do-card.default .text-wrapper {
  color: #000000;
}

.work {
    align-items: center;
    background-color: #d6f5db;
    border-radius: 20px;
    display: inline-flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    justify-content: center;
    padding: 30px;
    position: relative;
  }
  
  .work .img-3 {
    height: 32.89px;
    position: relative;
    width: 33.32px;
  }
  
.what-we-do {
    height: 800px;
    width: 100%;
  }
  
  .what-we-do .div {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    grid-gap: 40px;
    gap: 40px;
    left: 105px;
    position: relative;
    top: 67px;
  }
  
  .what-we-do .frame-2 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
    position: relative;
  }
  
  .what-we-do .text-wrapper-2 {
    color: #20b15a;
    font-family: "Poppins", Helvetica;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  
  .what-we-do .p {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: 495px;
  }
  
  .what-we-do .frame-3 {
    align-items: center;
    display: flex;
    grid-gap: 220px;
    gap: 220px;
    position: relative;
  }
  
  .what-we-do .what-we-do-card-instance {
    height: 14px !important;
    margin-right: unset !important;
    width: 16px !important;
  }
  
  .what-we-do .design-component-instance-node {
    flex: 0 0 auto !important;
  }
  
.testimonial-section {
    height: 740px;
    position: relative;
    width: 1440px;
}

.testimonial-section .frame-2 {
    align-items: flex-start;
    display: inline-flex;
    grid-gap: 535px;
    gap: 535px;
    left: 112px;
    position: absolute;
    top: 122px;
}

.testimonial-section .frame-3 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    grid-gap: 24px;
    gap: 24px;
    position: relative;
}

.testimonial-section .text-wrapper-4 {
    color: #20b15a;
    font-family: "Poppins-Medium", Helvetica;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.testimonial-section .see-what-our {
    color: #000000;
    font-family: "Poppins-SemiBold", Helvetica;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: 495px;
}

.testimonial-section .img {
    flex: 0 0 auto;
    position: relative;
}

.testimonial-section .frame-wrapper {
    height: 260px;
    left: 124px;
    overflow-x: scroll;
    position: absolute;
    top: 362px;
    width: 1316px;
}

.testimonial-section .frame-4 {
    align-items: flex-start;
    display: inline-flex;
    grid-gap: 45px;
    gap: 45px;
    padding: 0px 100px 0px 0px;
    position: relative;
}

.testimonial-section .frame-29 {
    flex: 0 0 auto !important;
}



.footer {
    background-color: #00ca72;
    height: 441px;
    position: relative;
    width: 100%;
  }
  
  .footer .overlap-group {
    height: 178px;
    left: 106px;
    position: absolute;
    top: 57px;
    width: 338px;
  }
  
  .footer .frame {
    align-items: flex-start;
    display: inline-flex;
    grid-gap: 19.6px;
    gap: 19.6px;
    left: 0;
    padding: 19.6px;
    position: absolute;
    top: 0;
  }
  
  .footer .text-wrapper {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 39.2px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.96px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  
  .footer .some-footer-text {
    color: #000000;
    font-family: "Avenir-Roman", Helvetica;
    font-size: 20px;
    font-weight: 400;
    left: 10px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 97px;
    width: 328px;
  }
  
  .footer .frame-wrapper {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    grid-gap: 27.94px;
    gap: 27.94px;
    left: 106px;
    position: absolute;
    top: 256px;
  }
  
  .footer .div {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    grid-gap: 15.31px;
    gap: 15.31px;
    position: relative;
  }
  
  .footer .group {
    background-color: #20b15a;
    border-radius: 20.41px;
    height: 40.82px;
    position: relative;
    width: 40.82px;
  }
  
  .footer .vector {
    height: 18px;
    left: 16px;
    position: absolute;
    top: 11px;
    width: 9px;
  }
  
  .footer .img {
    height: 14px;
    left: 13px;
    position: absolute;
    top: 14px;
    width: 17px;
  }
  
  .footer .vector-2 {
    height: 15px;
    left: 13px;
    position: absolute;
    top: 11px;
    width: 15px;
  }
  
  .footer .frame-2 {
    flex: 0 0 auto;
    position: relative;
  }
  
  .footer .text-wrapper-2 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 20px;
    font-weight: 600;
    left: 681px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 94px;
  }
  
  .footer .text-wrapper-3 {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 20px;
    font-weight: 600;
    left: 1066px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 92px;
  }
  
  .footer .frame-3 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    grid-gap: 25px;
    gap: 25px;
    left: 681px;
    position: absolute;
    top: 141px;
  }
  
  .footer .text-wrapper-4 {
    color: #000000;
    font-family: "Avenir-Medium", Helvetica;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  
  .footer .text-wrapper-5 {
    color: #000000;
    font-family: "Avenir-Medium", Helvetica;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  
  .footer .design-agency-head {
    color: #000000;
    font-family: "Avenir-Medium", Helvetica;
    font-size: 20px;
    font-weight: 500;
    left: 1066px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 148px;
  }
  
  .footer .text-wrapper-6 {
    color: #000000;
    font-family: "Avenir-Medium", Helvetica;
    font-size: 20px;
    font-weight: 500;
    left: 116px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 384px;
  }
  
/* attribute-card.css */
.attribute-card-container {
    display: flex;
    justify-content: center;
    background-color: #f9fffa;
  }
  
.attribute-card {
    padding: 20px;
    margin: 10px;
    text-align: center;
  }
.attribute-card:hover{
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.attribute-icon {
    margin-bottom: 10px;
}
.attribute-title {
    height: 24px;
    width: 160px;
}
.attribute-title p {
    color: #000000;
    font-family: "Poppins-Medium", Helvetica;
    font-size: 16px;
    font-weight: 500;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    text-align: center;
}

.attribute-value p {
    color: #20b15a;
    font-family: "Avenir-Black", Helvetica;
    font-size: 40px;
    font-weight: 900;
    left: 0;
    letter-spacing: 0;
    position: relative;
    top: 0;
}
.our-portfolio {
    height: 1626px;
    position: relative;
    width: 100%;
}

.our-portfolio .text-wrapper {
    color: #20b15a;
    font-family: "Poppins-Medium", Helvetica;
    font-size: 20px;
    font-weight: 500;
    left: 113px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 61px;
}

.our-portfolio .div {
    color: #000000;
    font-family: "Poppins-SemiBold", Helvetica;
    font-size: 30px;
    font-weight: 600;
    left: 113px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 115px;
    width: 495px;
}

.our-portfolio .frame {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    grid-gap: 42.79px;
    gap: 42.79px;
    left: 113px;
    position: absolute;
    top: 279px;
}

.our-portfolio .screenshot {
    height: 342.31px;
    object-fit: cover;
    position: relative;
    width: 561px;
}

.our-portfolio .frame-2 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    grid-gap: 14.26px;
    gap: 14.26px;
    position: relative;
}

.our-portfolio .text-wrapper-2 {
    color: #000000;
    font-family: "Poppins-SemiBold", Helvetica;
    font-size: 22.8px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.95px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.our-portfolio .p {
    color: #000000;
    font-family: "Avenir-Book", Helvetica;
    font-size: 15.2px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: 533.43px;
}

.our-portfolio .frame-3 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    grid-gap: 42.64px;
    gap: 42.64px;
    left: 769px;
    position: absolute;
    top: 279px;
}

.our-portfolio .img {
    height: 521.21px;
    object-fit: cover;
    position: relative;
    width: 561.15px;
}

.our-portfolio .frame-4 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    grid-gap: 14.21px;
    gap: 14.21px;
    position: relative;
}

.our-portfolio .text-wrapper-3 {
    color: #000000;
    font-family: "Poppins-SemiBold", Helvetica;
    font-size: 22.7px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.95px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.our-portfolio .text-wrapper-4 {
    color: #000000;
    font-family: "Avenir-Book", Helvetica;
    font-size: 15.2px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: 531.63px;
}

.our-portfolio .frame-5 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    grid-gap: 42.79px;
    gap: 42.79px;
    left: 113px;
    position: absolute;
    top: 874px;
}

.our-portfolio .screenshot-2 {
    height: 522.97px;
    position: relative;
    width: 561px;
}

.our-portfolio .frame-6 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    grid-gap: 55.4px;
    gap: 55.4px;
    left: 769px;
    position: absolute;
    top: 1050px;
}

.our-portfolio .screenshot-3 {
    height: 338.03px;
    object-fit: cover;
    position: relative;
    width: 554px;
}

.our-portfolio .frame-7 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    grid-gap: 14.08px;
    gap: 14.08px;
    position: relative;
}

.our-portfolio .text-wrapper-5 {
    color: #000000;
    font-family: "Poppins-SemiBold", Helvetica;
    font-size: 22.5px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.94px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.our-portfolio .text-wrapper-6 {
    color: #000000;
    font-family: "Avenir-Book", Helvetica;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: 526.77px;
}

