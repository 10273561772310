.what-we-do-card {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  gap: 43px;
  height: 415px;
  padding: 50px 30px;
  position: relative;
  width: 380px;
}

.what-we-do-card .frame {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15px;
  position: relative;
}

.what-we-do-card .grow-your-business {
  color: #000000;
  font-family: "Poppins", Helvetica;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.what-we-do-card .we-help-identify-the {
  color: #000000;
  font-family: "Avenir-Roman", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 299px;
}

.what-we-do-card .learn-more-btn {
  all: unset;
  align-items: center;
  border-radius: 10px;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 15px 20px;
  position: relative;
}

.what-we-do-card .text-wrapper {
  font-family: "Avenir-Roman", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.what-we-do-card .vector {
  height: 16px;
  margin-right: -1px;
  position: relative;
  width: 18px;
}

.what-we-do-card .activity-instance {
  flex: 0 0 auto !important;
}

.what-we-do-card.hover {
  box-shadow: 0px 4px 20px #0000001a;
}

.what-we-do-card.hover .learn-more-btn {
  background-color: #f55f1d;
}

.what-we-do-card.hover .text-wrapper {
  color: #ffffff;
}

.what-we-do-card.default .text-wrapper {
  color: #000000;
}
