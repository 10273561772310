.header-section {
    background-color: #d6f5db;
    height: 959px;
    position: relative;
    width: 100%;
  }
  
  .header-section .frame {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 42.41px;
    left: 115px;
    position: absolute;
    top: 272px;
  }
  
  .header-section .header-text-content {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 11.46px;
    position: relative;
  }
  
  .header-section .text-wrapper {
    color: #000000;
    font-family: "Poppins", Helvetica;
    font-size: 57.3px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 74.5px;
    margin-top: -1.15px;
    position: relative;
    width: 630.44px;
  }
  
  .header-section .we-help-businesses {
    color: #000000;
    font-family: "Avenir-Medium", Helvetica;
    font-size: 22.9px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    width: 541.03px;
  }
  
  .header-section .header-CTA {
    all: unset;
    align-items: center;
    background-color: #20b15a;
    border-radius: 18.34px;
    box-sizing: border-box;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 11.46px;
    justify-content: center;
    padding: 22.93px 34.39px;
    position: relative;
  }
  
  .header-section .div {
    color: #ffffff;
    font-family: "Poppins", Helvetica;
    font-size: 22.9px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1.15px;
    position: relative;
    width: fit-content;
  }
  
  .header-section .our-partners {
    background-color: #f7fff8;
    height: 138px;
    left: 0;
    position: absolute;
    top: 783px;
    width: 100%;
  }
  
  .header-section .group {
    height: 36px;
    left: 132px;
    position: relative;
    top: 50px;
    width: 1355px;
  }
  
  .header-section .logos-monday {
    height: 33px;
    left: 608px;
    position: absolute;
    top: 0;
    width: 180px;
  }
  
  .header-section .logos-google {
    height: 33px;
    left: 0;
    position: absolute;
    top: 3px;
    width: 102px;
  }
  
  .header-section .trello-logo-blue {
    height: 33px;
    left: 274px;
    object-fit: cover;
    position: absolute;
    top: 3px;
    width: 163px;
  }
  
  .header-section .notion {
    height: 33px;
    left: 960px;
    object-fit: cover;
    position: absolute;
    top: 3px;
    width: 92px;
  }
  
  .header-section .slack {
    height: 33px;
    left: 1224px;
    object-fit: cover;
    position: absolute;
    top: 3px;
    width: 131px;
  }  